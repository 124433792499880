import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../asset/img/logo.png";
import { useSelector } from "react-redux";

function Header() {
  const userId = useSelector((state) => state.app.userId);
  const [ifSend, setIfSend] = useState(false);
  return (
    <>
      <>
        <div className="logoBox">
          <a href={`/${userId}`}>
            <img className="w-[72%] m-auto" src={Logo} alt="" />
          </a>
        </div>
      </>
    </>
  );
}

export default Header;
