import React, { useEffect } from "react";
import Login from "../component/Login";
import { useDispatch } from "react-redux";
import { STORE_HOSTNAME } from "../store/slice/Appslice";

function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    const hostName = window.location.host;
    dispatch(STORE_HOSTNAME(hostName));
  }, []);
  return (
    <>
      <div className="login_form_box max-w-[400px] m-auto mt-4">
        <Login />
      </div>
    </>
  );
}

export default Home;
