import React from "react";
import Routes from "./Routes";
// import style
import "./asset/scss/App.scss";

// import component
import Header from "./component/Header";
import Footer from "./component/Footer";

function App() {
  return (
    <div className="App min-h-screen py-5 flex items-center">
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto">
            {/* header component */}
            <div className="link-bar">
              <Header></Header>
            </div>
            {/* main component */}
            <div className="mainApp">
              <Routes />
            </div>
            {/* footer component */}
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
