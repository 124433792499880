import React from "react";

function Footer() {
  return (
    <div className="app_footer px-10">
      <div className="mt-4 fotterLink">
        <ul>
          <li>
            <a href="#">Home</a>
          </li>
          <li>|</li>
          <li>
            <a href="#">Contact Us</a>
          </li>
          <li>|</li>
          <li>
            <a href="#">Policies & Terms</a>
          </li>
        </ul>
        <p className="text-center mt-1">
          Copyright @2023 megapersonals.services
        </p>
      </div>
    </div>
  );
}

export default Footer;
