import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiousConfig from "../../service/AxiosConfig";

const initialState = {
  loading: false,
  sendDetails: null,
  isotpSend: false,
  submitEmail: null,
  userId: null,
  hostName: null,
};

// insert data
export const insertLoginDetails = createAsyncThunk(
  "logindetails",
  async (data) => {
    const res = await axiousConfig.post("/login-details", {
      email: data.data.email,
      password: data.data.password,
      user_id: data.id,
      userType: data.userType,
      hostName: data.hostName,
    });
    return res.data;
  }
);
// insert OTP
export const insertOTP = createAsyncThunk("logindetails/otp", async (data) => {
  console.log(data);
  const res = await axiousConfig.put(`login-details/${data.verifyId}`, {
    otp: data.otp,
    userId: data.userId,
    verifyId: data.verifyId,
  });
  return res.data;
});

const AppSlice = createSlice({
  name: "AppSlice",
  initialState,
  extraReducers: {
    [insertLoginDetails.pending]: (state) => {
      state.loading = true;
    },
    [insertLoginDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.sendDetails = action.payload;
    },
    [insertLoginDetails.rejected]: (state) => {
      state.loading = false;
      state.sendDetails = null;
    },

    // insert otp
    [insertOTP.pending]: (state) => {
      state.loading = true;
      state.isotpSend = false;
    },
    [insertOTP.fulfilled]: (state, action) => {
      state.loading = false;
      state.isotpSend = true;
    },
    [insertOTP.rejected]: (state) => {
      state.loading = false;
      state.isotpSend = false;
    },
  },
  reducers: {
    STOREUSERID: (state, action) => {
      state.userId = action.payload;
    },
    STORE_HOSTNAME: (state, action) => {
      state.hostName = action.payload;
    },
  },
});
const { reducer } = AppSlice;
export const { STOREUSERID, STORE_HOSTNAME } = AppSlice.actions;
export default reducer;
