import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { insertOTP } from "../store/slice/Appslice";
function OtpForm() {
  const dispatch = useDispatch();
  const loadingState = useSelector((state) => state.app.loading);
  const sendDetails = useSelector((state) => state.app.sendDetails);
  const userId = useSelector((state) => state.app.userId);
  const { id, name } = useParams();
  const [verifyId, setVarifyId] = useState(null);
  useEffect(() => {
    if (sendDetails != null) {
      setVarifyId(sendDetails.verify_id);
    } else {
      setVarifyId(id);
    }
  }, []);
  const { register, handleSubmit, reset, formState } = useForm();
  const { errors } = formState;
  function onSubmit(data) {
    const dataSend = {
      otp: data.otp,
      verifyId: verifyId,
      userId: userId || name,
    };
    dispatch(insertOTP(dataSend))
      .unwrap()
      .then((res) => {
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="mainOptp">
        <form className="mainFormBox" onSubmit={handleSubmit(onSubmit)}>
          <div className="singleInputBox max-w-[300px] m-auto">
            <div className="left">
              <input
                type="text"
                className="block w-full px-2 border-2 border-[#888888] rounded-md font-semibold text-[#999] focus:!border-[#66afe9] text-[18px] h-[40px] text-center"
                {...register("otp", { required: true })}
              />
              {errors.otp?.type === "required" && (
                <small className="validationError">Please enter your OTP</small>
              )}
            </div>
            <div className="right mt-3 text-center">
              {loadingState && (
                <button
                  type="button"
                  className="px-5 bg-orange-300 text-white uppercase rounded-md h-[44px] text-[19px] hover:bg-orange-500 transition-all"
                >
                  Verifying...
                </button>
              )}
              {!loadingState && (
                <button
                  type="submit"
                  className="px-5 bg-orange-300 text-white uppercase rounded-md h-[44px] text-[19px] hover:bg-orange-500 transition-all"
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OtpForm;
